import React, { useEffect } from "react";

function WistiaPlayer({ button, wrapper }) {
  useEffect(() => {
    // Wistia embed code
    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/hdz38hibf3.jsonp`;
    script1.async = true;
    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;
    const div = document.createElement("div");
    div.innerHTML = `<span class="wistia_embed wistia_async_hdz38hibf3 popover=true popoverContent=link videoFoam=true" style="display:inline;position:relative">${button}</span>`;
    const container = document.getElementById(wrapper);
    container.appendChild(script1);
    container.appendChild(script2);
    container.appendChild(div);

    return () => {
      // Cleanup code
      container.innerHTML = "";
    };
  }, []);

  return <span id={`${wrapper}`}></span>;
}

export default WistiaPlayer;