import styled from 'styled-components/macro';
import {transparentize} from "polished"
import {
    Box,
    Heading,
    Paragraph,
    Button
} from '~styled';
const propToColor = (arg = "#442cd6" ) => transparentize(0.70, arg);
const Integration = styled(Box)`
position: relative;
padding-top: 60px;
padding-bottom: 60px;
@media (min-width: 768px){
  padding-top: 75px;
  padding-bottom: 80px;
}
@media (min-width: 992px){
  padding-top: 102px;
  padding-bottom: 100px;
}
`
Integration.Title = styled(Heading)`
    font-size: 32px;
    font-weight: 500;
    margin-top: 30px;
    letter-spacing: normal;
    line-height: 1.4423;
    margin-bottom: 32px;
    @media (min-width:575px) {
      font-size: 25px;
    }
    @media (min-width:768px) {
      font-size: 42px;
    }
    @media (min-width:992px) {
      font-size: 52px;
    }
`
Integration.Text = styled(Paragraph)`
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.61904;
    @media (min-width:575px) {
      font-size: 14px;
    }
    @media (min-width:992px) {
      font-size: 21px;
    }
`
Integration.Image = styled(Box)`
position: relative;
margin-bottom: 40px;
width: auto;
height: auto;
@media (min-width: 992px){
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    width: 348px;
    height: 312px;
}
@media (min-width: 1600px){
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 516px;
  height: 460px;
}

`
Integration.Button = styled(Button)`
    border-radius: 10px;
    transition:.4s;
    margin-bottom: 30px;
    margin-top: 50px;
    border-color:${props => props.backgroundColor? props.backgroundColor: "#5034fc" };
    background:${props => props.backgroundColor? props.backgroundColor: "#5338FC" };
    box-shadow:  ${props => "0 4px 4px " + propToColor(props.backgroundColor)};
  &:hover{
    transform: translateY(-5px);
    border-color:${props => props.hoverBackgroundColor? props.hoverBackgroundColor: "#442cd6" };
    background:${props => props.hoverBackgroundColor? props.hoverBackgroundColor: "#442cd6" };
    box-shadow:  ${props => "0 4px 4px " + propToColor(props.hoverBackgroundColor)};
  }
`
Integration.Box = styled(Box)`

`
export default Integration;