import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import HeroSection from '~sections/project/Hero'
import ContentOne from '~sections/project/ContentOne'
import ContentTwo from '~sections/project/ContentTwo'
import ContentThree from '~sections/project/ContentThree'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import Testimonial from '../sections/customer-stories/Testimonial'
import Integration from "~sections/project/Integration"
import ContentBlocks from "~sections/project/Blocks/Blocks"
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Marketing() {
  return (
    <PageWrapper headerConfig={header}>
      <HeroSection/>
      <Testimonial />
      <ContentOne/>
      <ContentTwo/>
      <ContentThree/>
      <ContentBlocks />
      <Integration />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
  )
}
