import React from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from "gatsby"
import img from '~assets/image/icons/Sales-marketing.svg';
import img2 from '~assets/image/icons/Recruiters.svg';
import img3 from '~assets/image/icons/Entrepreneurs-Experts.svg';

const ContentBlocks=({id})=>{

    return(
   
<Content id={id}>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="We-Connect offers solutions for:"/> </Content.Title>
      </Content.TextBlock>  
      
    </Row>
    <Row className="justify-content-center">
     
        <Col xs="12" className="col-lg-4 col-md-8 col-sm-9 col-xs-10">
        
        <Card style={{  height: '40rem', borderRadius: '10px', marginBottom:"40px", marginLeft:"auto", marginRight:"auto"}}>
        
        <Card.Body>
          <Row>
        <Col style={{ paddingRight: 0, paddingLeft: 25,}} className="col-xl-3 col-lg-3 col-xs-3 col-md-3 col-3"><img src={img} alt="icon" /></Col>
        <Col style={{ paddingLeft: 0, paddingRight: 0 }} className="col-xl-9 col-lg-6 col-xs-6 col-md-6 col-9"><Content.Box><h1 className="block-title pt-3"> Sales & Marketing</h1></Content.Box></Col>
        </Row>
          <Content.SmallText className="mt-5">
          Seeking to exceed your revenue goals?
          </Content.SmallText>
          <Content.CardText>
          We-Connect is tailored for you. Our platform is designed to help you reach and engage with the right buyers, leverage your network for additional opportunities, and ensure high-quality, consistent LinkedIn sales outreach.
          </Content.CardText>
          <Content.Box className="pt-2 pt-lg-0 pt-xl-2"><Link to="/sales-marketing"><Content.Button className="btn btn-primary text-white">Start 10x more conversions</Content.Button></Link></Content.Box>
        </Card.Body>
        
      </Card>
        </Col>
        <Col xs="12" className="col-lg-4 col-md-8 col-sm-9 col-xs-10">
        <Card style={{ height: '40rem', borderRadius: '10px', marginBottom:"40px",marginLeft:"auto", marginRight:"auto" }}>
        
        <Card.Body>
          <Row>
          <Col style={{ paddingLeft: 20,}} className="col-xl-3 col-lg-3 col-xs-3 col-md-3 col-3"><img src={img2} alt="icon" /></Col>
          <Col className="col-xl-6 col-lg-6 col-xs-6 col-md-6 col-6"><Content.Box><h1 className="block-title pt-3"> Recruiters </h1></Content.Box></Col>
          </Row>
        
          
          <Content.SmallText className="mt-6">
          Struggling to find top talent? 
          </Content.SmallText>
          <Content.CardText className="mt-6 pt-xl-6">
          We-Connect accelerates your search. Our solution empowers you to locate better candidates faster, spend less time sourcing, and start conversations with those elusive, top-notch prospects.
          </Content.CardText>
          <Content.Box className="pt-5"><Link to="/recruiters"><Content.Button className="btn btn-primary text-white">Accelerate your search</Content.Button></Link></Content.Box>
        </Card.Body>
        
      </Card>
        </Col>
        <Col xs="12" className="col-lg-4 col-md-8 col-sm-9 col-xs-10">
        <Card style={{ height: '40rem', borderRadius: '10px', marginBottom:"40px",marginLeft:"auto", marginRight:"auto" }}>
        
        <Card.Body>
        <Row>
          <Col style={{ paddingLeft: 15,}} className="col-xl-3 col-lg-3 col-xs-3 col-md-3 col-3"><img src={img3} alt="icon" /></Col>
          <Col className="col-xl-7 col-lg-6 col-xs-6 col-md-6 col-7"><Content.Box><h1 className="block-title"> Entrepreneurs & Experts </h1></Content.Box></Col>
          </Row>
          <Content.SmallText className="mt-5">
          Aspiring to be a leader in your field?
          </Content.SmallText>
          <Content.CardText>
          We-Connect expands your LinkedIn influence with powerful LinkedIn networking capabilities. We equip you to amplify your message, connect with industry peers, and forge meaningful partnerships to boost your visibility and credibility.
          </Content.CardText>
          <Content.Box className="pb-2"><Link to="/entrepreneurs"><Content.Button className="btn btn-primary text-white">Expand your influence</Content.Button></Link></Content.Box>
        </Card.Body>
        
      </Card>
        </Col>
               
    </Row>

    </Content.Inner>
  </Container>
</Content>
)
}
export default ContentBlocks