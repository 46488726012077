import React from 'react'
import Integration from './style'
//import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Row, Container  } from 'react-bootstrap'
import { Link } from "gatsby";
import img from '../../../assets/image/media/EmailAutomation/SVG/CRM-Integration-for-Seamless-Coordination.svg'
import { SuperTag } from '~components'
export default function IntegrationSection({backgroundColor,colClasses,...rest}){
return(
<Integration backgroundColor="#F3F4F6" {...rest}>
  <Container>
    <Row className="row justify-content-center text-center">
      <Col className={colClasses ? colClasses : "col-xl-8"}>
        <Integration.Title as="h2">Integrations with tools you love</Integration.Title>
        <Integration.Image>
        <img src={img} className="w-100" alt="content" layout="fullWidth"  placeholder="blurred" />
        </Integration.Image>
        <Link to="/integrations"><Integration.Button textColor="#fff" hoverTextColor="#fff" backgroundColor={backgroundColor}  hoverBackgroundColor={backgroundColor} sizeX="180px" sizeY="56px" rounded="true">See all integrations</Integration.Button></Link>
      </Col>
      
    </Row>
  </Container>
  
</Integration>
)
}