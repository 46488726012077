import styled from 'styled-components/macro';
import { Link } from '~components';
import { Box, Heading,Button,Paragraph } from '~styled';

const Content = styled(Box)`
    position: relative;
`
Content.Inner = styled(Box)`
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;
    border-bottom:1px solid rgba(128, 138, 142, 0.2);
    @media (min-width: 768px){
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width: 992px){
        padding-top: 132px;
        padding-bottom: 135px;
    }
    .card {
        box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
        transition: all ease 200ms;
        border: 1px solid #ECECEC;
        width: 368px;
        @media (min-width: 992px) {
            width: 310px;
        }
        @media (min-width: 1200px) {
            width: 368px;
        }
    }

    .card:hover {   
        transform: scale(1.03);  
        box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
      }
    .block-title {
        color: #1d293f;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 22px;
        display: inline-block;
    } 
    .img{
        margin-left: 10px;
    } 

`
Content.TextBlock=styled(Box)`

`
Content.Block = styled(Box)`
padding-top: 80px;
@media (min-width: 768px) {
    padding-top: 0;
}

`
Content.Title = styled(Heading)`
text-align: center;
font-size: 35px;
font-weight: 500;
letter-spacing: -2px;
line-height: 1.20689;
margin-bottom: 60px;

@media (min-width: 480px) {
    font-size: 38px;
}

@media (min-width: 768px) {
    font-size: 48px;
}

@media (min-width: 992px){
    font-size: 58px;
}
`

Content.Text = styled(Paragraph)`
font-size: 18px;
font-weight: 400;
font-style: normal;
letter-spacing: normal;
line-height: 1.66666;
margin-bottom: 12px;

`
Content.CardText = styled(Paragraph)`
font-size: 18px;
text-align: center;
color: #000;
font-weight: 400;
font-style: normal;
letter-spacing: normal;
line-height: 1.66666;
margin-bottom: 50px;
@media (min-width: 992px){
    margin-bottom: 40px;
}
@media (min-width: 1200px){
    margin-bottom: 50px;
}

`
Content.SmallText = styled(Paragraph)`
font-size: 22px;
text-align: center;
color: #000;
font-weight: 400;
font-style: normal;
letter-spacing: normal;
line-height: 1.66666;
margin-bottom: 50px;
`

Content.Image = styled(Box)`
position: relative;
margin-bottom: 40px;

@media (min-width: 992px){
    margin-left: 45px;
    margin-bottom: 0;
}

`
Content.SectionShape=styled(Box)`
    position: absolute;
    left: -3.8%;
    top: 12%;
    z-index: 0;
    width: 9%;
`
Content.ContentShape = styled(Box)`
    width: 38%;
    position: absolute;
    right: 13%;
    bottom: 0;
    z-index: -1;

`
Content.Button = styled(Link)`
border-radius: 15px;
padding: 16px 35px;
margin-top: 40px;
min-width:100%;
font-size: 12px;

@media (min-width:992px){
    margin-top: 0px;

}

@media (min-width:1200px){
        min-width:137px;
        right: 10px;
        transform: translateY(-50%);
        top: 50%;
        margin-top: 50px;

    }
`

Content.Box = styled(Box)`
h1{
    font-size: 24px;
    margin-bottom: 0px !important;
}
`


export default Content;