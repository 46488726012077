import React from 'react'
//import { StaticImage as  Img } from "gatsby-plugin-image";
import Hero from './style'
import { Col, Container, Row } from 'react-bootstrap';
import img from "../../../assets/image/We-connect-website-animation.svg";
import WistiaPlayer from '~components/WistiaPlayer/WistiaPlayer';
//import { SuperTag } from '~components';
export default function HeroSection(){
  return(
<Hero className="position-relative bg-default">
  <Container>
  <Row>
      <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-11 order-2 order-lg-1"
              xs="12">
        <Hero.ContentBlock mb="35px">
        <Hero.Title as="h1">
                  Unlock the Power of Your LinkedIn Network
        </Hero.Title>          
        
                <Hero.Text>
                Connect with buyers, expand your network, and build your reach with <b>sales automation for LinkedIn  </b>
                 
                </Hero.Text>
        </Hero.ContentBlock>
        {/* Newsletter */}
        <Hero.NewsletterBox>
          <Hero.Newsletter>
            <form action="//account.we-connect.io/signup"  target="_blank" form-id="kt_login_signup_form" novalidate>
              <Hero.InputBox>
                <input placeholder="@ Enter your email" type="email" name="email" required ps-email-field-id="eab3eabe-8d1d-4025-8361-8694d9c7b13b" className="form-control"/>
                <Hero.Button  className="btn btn-primary text-white" type="submit" ps-submit-button-id="182620e1-2a48-45ce-b154-8b4cc46913a1">Get Started</Hero.Button>
                
              </Hero.InputBox>
            </form>
            
          </Hero.Newsletter>
          <Hero.NewsletterText ml="18px" mt="18px">
          14 day free trial | No credit card required.
          </Hero.NewsletterText>
          <Hero.Video mt="30px">
          <WistiaPlayer 
            className="video-block"
            wrapper="wistia-player-container-1"
            button="<button 
            style='display: flex;
            align-items: center;
            background:none;
            border:0;
            box-shadow:none;
            text-align:left;
            outline:none;
            box-shadow:none;'>
            <i  class='play-btn'
             />
            </button>"
            
          />
          <span className="btn-text">Watch our 2 min demo video</span>
          </Hero.Video>
        </Hero.NewsletterBox>
        {/*/ .Newsletter */}
      </Col>
      <Col xs="14" className="col-xl-6 col-lg-6 col-md-8 col-xs-11 order-1 order-lg-2">
        <Hero.Image>
        <object 
        type="image/svg+xml" data={img} className="w-100">
        </object>
        </Hero.Image>
      </Col>
    </Row>
  </Container>
  
</Hero>
)
}